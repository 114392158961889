import {
  ExamMarksState,
  ExamMarksRecord
} from './../reducers/exam-marks.state';
import * as actions from '../../exam-marks/actions/exam-marks.action';
import { get, uniqBy, filter } from 'lodash';

export const initialState: ExamMarksState = (new ExamMarksRecord() as unknown) as ExamMarksState;

export function reducer(
  state = initialState,
  { type, payload }: any
): ExamMarksState {
  if (!type) {
    return state;
  }

  switch (type) {

    case actions.ActionTypes.GET_EXAM_MARKS:
      return Object.assign({}, state, {
        getExamMarks: null,
        getExamMarksLoading: true,
        getExamMarksLoaded: false,
        getExamMarksFailed: false,
      });

    case actions.ActionTypes.GET_EXAM_MARKS_SUCCESS:
      return Object.assign({}, state, {
        getExamMarks: payload.Data,
        getExamMarksLoading: false,
        getExamMarksLoaded: true,
        getExamMarksFailed: false,
      });

    case actions.ActionTypes.GET_EXAM_MARKS_FAIL:
      return Object.assign({}, state, {
        getExamMarksLoading: false,
        getExamMarksLoaded: false,
        getExamMarksFailed: true,
      });


   

    default: {
      return state;
    }
  }
}

/**
 * export values
 */

export const getExamMarks = (state: ExamMarksState) => state.getExamMarks;
export const getExamMarksLoading = (state: ExamMarksState) => state.getExamMarksLoading;
export const getExamMarksLoaded = (state: ExamMarksState) => state.getExamMarksLoaded;
export const getExamMarksFailed = (state: ExamMarksState) => state.getExamMarksFailed;

