import { Action } from '@ngrx/store';
import { type } from './../../shared/utility';

export const ActionTypes = {

    GET_EXAM_MARKS: type('[examMarks] GET_EXAM_MARKS'),
    GET_EXAM_MARKS_SUCCESS: type('[examMarks] GET_EXAM_MARKS_SUCCESS'),
    GET_EXAM_MARKS_FAIL: type('[examMarks] GET_EXAM_MARKS_FAIL'),

};


export class getExamMarks implements Action {
    type = ActionTypes.GET_EXAM_MARKS;
    constructor(public payload: any) {
    }
}
export class getExamMarksSuccess implements Action {
    type = ActionTypes.GET_EXAM_MARKS_SUCCESS;
    constructor(public payload: any) {
    }
}
export class getExamMarksFail implements Action {
    type = ActionTypes.GET_EXAM_MARKS_FAIL;
    constructor(public payload: any) {
    }
}





export type Actions =
    | getExamMarks
    | getExamMarksSuccess
    | getExamMarksFail

