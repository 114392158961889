import { Map, Record } from 'immutable';

export interface ExamMarksState extends Map<string, any> {

    getExamMarks:any;
    getExamMarksLoading: boolean;
    getExamMarksLoaded: boolean;
    getExamMarksFailed: boolean;


}

export const ExamMarksRecord = Record({

    getExamMarks: [],
    getExamMarksLoading: false,
    getExamMarksLoaded: false,
    getExamMarksFailed: false,



});
